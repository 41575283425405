import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Link } from 'gatsby-material-ui-components'
import * as React from 'react'
import { Layout } from 'src/components/organisms/layouts'
import Copyright from '../components/Copyright'
import ProTip from '../components/ProTip'

export default function About() {
  return (
    <Layout>
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Gatsby example
        </Typography>
        <Link to="/">Go to the main page</Link>
        <ProTip />
        <Copyright />
      </Box>
    </Layout>
  )
}
